window.Popper = require('popper.js').default;
window.$ = $ = window.jQuery = require('jquery');

require('bootstrap');
require('datatables.net');
require('datatables.net-dt');
require('@selectize/selectize');

$(function () {
    $('.selectize').selectize();
});

$(document).ready(function () {
    $('.datatables').DataTable();

    $('#date-start-input').datetimepicker({
        format: 'YYYY-MM-DD',
        useCurrent: false,
        // inline: true
    });
    $('#date-end-input').datetimepicker({
        format: 'YYYY-MM-DD',
        useCurrent: false,
        // inline: true
    });

    $('#campaign-report-btn').click(function () {
        document.location.href = '/campaign-report/' + $('#date-start-input').val() + '/' + $('#date-end-input').val();
    });

    if ($('#campaign-report-chart-div').length > 0) {
        $.ajax('/campaign-report/chart/' + $('#date-start-input').val() + '/' + $('#date-end-input').val()).done(
            function (data) {
                initChart(data);
            }
        );
    }

    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
});
